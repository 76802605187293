import kpiConfigService from '@/services/kpiConfigService.js'

function initialState () {
  return {
    config: [],
    isLoaded: null
  }
}

export default {
  state: {
    config: [],
    isLoaded: null
  },
  mutations: {
    loadKpisConfig (state, payload) {
      if (payload.length === 0) return
      payload.forEach(element => {
        state.config.push(element.kpiType)
      })
    },
    setIsLoaded (state, isLoaded) {
      state.isLoaded = isLoaded
    },
    deleteKpiConfig (state, description) {
      // console.log(description)
      const a = state.config.find(x => x.description === description)
      // console.log(state.config.indexOf(a))
      if (a > -1) state.config.splice(state.config.indexOf(a), 1)
    },
    addKpiConfig (state, kpiType) {
      // console.log('entra aqui', kpiType)
      state.config.push(kpiType)
    },
    resetKpiConfig (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    getKpiConfig (state) {
      return state.config
    },
    getKpiConfigLoaded (state) {
      return state.isLoaded
    }
  },
  actions: {
    fetchKpisConfig ({ commit, state }) {
      if (state.config.length === 0) {
        kpiConfigService.getConfig()
          .then((response) => {
            commit('loadKpisConfig', response.data) // datos de pruebas
            commit('setIsLoaded', true)
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            // console.log('finally 3')
          })
      }
    },
    addKpiConfig ({ commit, state }, data) {
      // console.log(data)
      return kpiConfigService.updateConfig(data)
        .then((response) => {
          // console.log('ok, updated')
          commit('addKpiConfig', response.data)
        })
        .finally(() => {
          // console.log('finally 3')
        })
    },
    deleteKpiConfig ({ commit, state }, data) {
      // console.log(data)
      kpiConfigService.deleteConfig(data)
        .then((response) => {
          // console.log('ok, deleted')
        })
        .catch((error) => {
          console.log('ERROR', error)
        })
        .finally(() => {
          // console.log('finally 3')
          commit('deleteKpiConfig', data)
        })
    }
  }
}
