<template>
  <div>
    <LineChart
      :data="chartData"
      :chartData="chartData"
      :options="chartOptions"
      :chartId="'line-chart'"
      :datasetIdKey="'label'"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    /><!-- :plugins="chartOptions.plugins" -->
  </div>
</template>

<script>
import LineChart from '@/components/digital-health/LineChart.vue'

/* TODO los segmentos podrían ser un objeto de la data que determinara el estilo para todos en vez
de tener que indicarlo en cada uno */

export default {
  name: 'BloodPressChart',
  components: { LineChart },
  props: {
    entity: {
      type: String,
      require: true,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      // eslint-disable-next-line
      type: String | Number,
      default: ''
    },
    measurement: {
      type: String,
      default: ''
    },
    borderColor: {
      type: Array,
      require: true,
      default: () => ['#ff0000', '#fff000']
    },
    xLabels: {
      type: Array,
      default: () => []
    },
    storeData: {
      type: Array,
      default: () => []
    },
    storeData2: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isLoading: false,
      skipped: (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined,
      down: (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined,
      up: (ctx, value) => ctx.p0.parsed.y < ctx.p1.parsed.y ? value : undefined,
      referenceData: [],
      width: 242, // 242 // 400 pruebas
      height: 200, // 274 // 360 pruebas
      chartData: {
        type: 'line',
        labels: this.xLabels, // debe ser prop
        datasets: [
          {
            // label: 'Data nº 1', // leyenda superior
            data: this.storeData,
            animation: true,
            // líneas
            borderColor: this.borderColor[0], // color borde
            borderWidth: 3,
            borderCapStyle: 'round', // líneas con final redondeado
            tension: 0.3, // 0: aristas
            showLine: true,
            fill: false,
            spanGaps: true, // mostrar línea en los huecos null
            segment: {
              borderColor: ctx => this.skipped(ctx, this.borderColor[0]) || this.down(ctx, this.borderColor[0]),
              borderDash: ctx => this.skipped(ctx, [4, 10])
            },
            // puntos
            pointStyle: 'circle',
            radius: 4, // tamaño del círculo
            backgroundColor: '#ffffff', // bg de los puntos
            hitRadius: 2,
            hoverBorderWidth: 5,
            hoverOffset: 4 // efecto hover?
            // yAxisID: 'yCustom1' // eje personalizado
          },
          {
            // label: 'Data nº 1', // leyenda superior
            data: this.storeData2,
            animation: true,
            // líneas
            borderColor: this.borderColor[1], // color borde
            borderWidth: 3,
            borderCapStyle: 'round', // líneas con final redondeado
            tension: 0.3, // 0: aristas
            showLine: true,
            fill: false,
            spanGaps: true, // mostrar línea en los huecos null
            // puntos
            pointStyle: 'circle',
            radius: 4, // tamaño del círculo
            backgroundColor: '#ffffff', // bg de los puntos
            hitRadius: 2,
            hoverBorderWidth: 5,
            hoverOffset: 4 // efecto hover?
            // yAxisID: 'yCustom1' // eje personalizado
          },
          {
            label: 'Reference data BOTTOM',
            data: [80, 80, 80, 80],
            radius: 0, // anulo el punto
            borderDash: [3, 5], // línea de puntos, espacio
            borderDashOffset: 0.5,
            borderSkipped: true,
            borderColor: '#ef9dd8',
            borderWidth: 1,
            borderRadius: 100,
            showLine: true, // false: oculta línea y muestra solo puntos
            animation: false
          },
          {
            label: 'Reference data TOP',
            data: [120, 120, 120, 120],
            radius: 0,
            borderDash: [3, 5],
            borderDashOffset: 0.5,
            borderSkipped: true,
            borderColor: '#36c5e4',
            borderWidth: 1,
            borderRadius: 100,
            showLine: true,
            animation: false
          }
        ]
        // hoverOffset: 4
      },
      chartOptions: {
        // todo lo que vaya en options va aquí.
        barThickness: 12, //  grosor de las barras
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: true, // animación toda la gráfica
        layout: { // marco del canvas
          padding: 0
          // padding: {
          //   left: 50,
          //   right: 10,
          // }
        },
        interaction: {
          mode: 'point' // point, nearest, index
          // TOOLTIP MODE: 'index' pinta los datos de todas las gráficas superpuestas TOP
        },
        plugins: {
          title: {
            display: false,
            text: this.title,
            padding: {
              top: 5,
              bottom: 5
            },
            color: 'red',
            font: {
              family: "'Calibri', sans-serif",
              size: 18,
              weight: 'lighter',
              style: 'normal'
            }
          },
          subtitle: {
            display: false,
            text: 'Custom Chart Subtitle',
            color: 'red',
            font: {
              family: "'Calibri', sans-serif",
              size: 14,
              weight: 'lighter',
              style: 'normal'
            }
          },
          legend: {
            display: false,
            labels: {
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 12,
                style: 'normal',
                weight: undefined,
                lineHeight: 1.2
              }
            }
          },
          tooltip: {
            // https://www.chartjs.org/docs/latest/configuration/tooltip.html
            enabled: true,
            position: 'average', // nearest
            // filter: tooltipItem => tooltipItem.datasetIndex === 0, // tooltip solo para la primera gráfica
            backgroundColor: '#000000b8',
            // borderColor: '#00ff00', // borde del tooltip
            // borderWidth: 3, // borde del tooltip
            // xAlign: 'center', // left, center, right
            // yAlign: 'bottom', // top, center, bottom
            titleFont: {
              family: "'Helvetica', 'Arial', sans-serif",
              size: 20,
              style: 'normal', // normal, italic, oblique, initial, inherit
              weight: 600,
              lineHeight: 'normal'
            },
            titleColor: '#fff',
            titleAlign: 'center', // center, left, right
            // titleSpacing: 10,
            titleMarginBottom: 10,
            bodyFont: {
              family: "'Helvetica', 'Arial', sans-serif",
              size: 16,
              style: 'normal', // normal, italic, oblique, initial, inherit
              weight: 400,
              lineHeight: 'normal'
            },
            bodyAlign: 'center', // center, left, right
            bodySpacing: 20,
            bodyColor: '#fff', // color texto cuerpo
            padding: 20,
            caretPadding: 10, // distancia extra entre el punto de aparición y el tooltip
            caretSize: 10, // tamaño de la flechita
            cornerRadius: 5,
            multiKeyBackground: '#ff0000',
            // mostrar o no el color del dato
            displayColors: false,
            boxWidth: 20, // ancho
            boxHeight: 10,
            boxPadding: 10,
            usePointStyle: true, // usar el mismo estilo de punto de la gráfica
            // callbacks: {} // para personalizar elementos
            callbacks: {
              label: function (context) {
                const target = 'mmHg'
                const number = new Intl.NumberFormat('es-ES').format(context.parsed.y)
                const label = `${number} ${target}`
                return label
              }
            }
          }
        },
        scales: {
          // https://www.chartjs.org/docs/latest/axes/cartesian/linear.html
          // type: 'logarithmic', // borrar
          // position: 'right', // borrar
          // stacked: true, // no me funciona
          ticks: {
            display: false, // elimino la escala por defecto de la gráfica
            fontSize: 6,
            // stepSize: 10,
            beginAtZero: true
          },
          // yCustom: { // eje personalizado de una gráfica
          //   color: 'blue'
          // },
          x: {
            display: true,
            // type: 'linear',
            // backgroundColor: '#ff0000',
            stacked: true,
            // min: 0,
            // max: 100,
            color: '#fffff',
            grid: {
              display: false, // mostrar parrilla X
              drawBorder: false, // línea X marcada
              lineWidth: 1, // grosor de línea
              color: '#FF0000' // color de línea
            },
            ticks: {
              display: true,
              color: '#B3B3B3',
              font: {
                family: "'Helvetica', 'Arial', sans-serif",
                size: 12,
                weight: 'lighter',
                style: 'normal'
              }
            }
          },
          y: {
            display: true,
            type: 'linear',
            // backgroundColor: '#ff0000',
            title: {
              display: false, // título del eje Y
              text: 'Eje Y'
            },
            stacked: false,
            min: this.minYlabels, // 40
            max: this.maxYlabels, // 220
            grid: {
              display: true,
              drawBorder: false,
              lineWidth: 1, // grosor de línea
              color: '#F6F6F6' // color de línea
            },
            ticks: {
              stepSize: this.stepSize,
              // maxTicksLimit: 7,
              display: true, // muestra los labels de Y
              color: '#B3B3B3',
              // showLabelBackdrop: true, // labels en cajas
              // backdropColor: '#ff0000', // labels en cajas
              // textStrokeColor: '#fff000', // labels en cajas
              // textStrokeWidth: 3, // labels en cajas
              font: {
                family: "'Helvetica', 'Arial', sans-serif",
                size: 12,
                weight: 'lighter',
                style: 'normal'
              }
            }
          }
        }
      }
    }
  },
  computed: {
    cssClasses () {
      return ''
    },
    styles () {
      return {}
    }
  },
  mounted () {
    // console.log('M', this.chartData.datasets[0].data)
  },
  methods: {
    colorTooltip (context) {
      if (context.tooltip) {
        return context.tooltip.dataPoints[0].raw >= 25 ? '#fab633' : context.tooltip.dataPoints[0].raw < 18.5 ? '#fab633' : '000000b8'
      }
    },
    minYlabels: function () {
      // const minValue = Math.min(...this.chartData.datasets[0].data)
      let minValue = Math.min(...this.chartData.datasets[0].data.filter(function (o) {
        if (o !== null) return o
      }))
      minValue = Math.trunc(((Math.trunc(Math.round(minValue) / 10) * 10) - (Math.ceil(Math.trunc(minValue) / 10) * 10) * 20 / 100) / 10) * 10
      if (minValue > this.chartData.datasets[2].data[0]) {
        return this.chartData.datasets[2].data[0]
      } else {
        return minValue
      }
    },
    maxYlabels: function () {
      const maxValue1 = Math.max(...this.chartData.datasets[0].data.filter(function (o) {
        if (o !== null) return o
      }))
      const maxValue2 = Math.max(...this.chartData.datasets[1].data.filter(function (o) {
        if (o !== null) return o
      }))
      const max = maxValue1 > maxValue2 ? maxValue1 : maxValue2
      let maxValue = Math.ceil((Math.ceil(Math.round(max) / 10) * 10) + (Math.ceil(Math.round(max) / 10) * 10) * 20 / 100)
      maxValue = Math.ceil(maxValue / 10) * 10
      // console.log('blood press', maxValue)
      // console.log(this.chartData.datasets[2].data[0])
      if (maxValue < this.chartData.datasets[3].data[0]) {
        return this.chartData.datasets[3].data[0]
      } else {
        return maxValue
      }
    },
    stepSize: function () {
      return (this.maxYlabels() - this.minYlabels()) / 4
    }
  }
}
</script>
