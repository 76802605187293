import moment from 'moment'
import kpiService from '@/services/kpiService.js'

function initialState () {
  return {
    hearing: [],
    hearingLeft: [],
    hearingRight: [],
    isLoading: false
  }
}

export default {
  state: {
    hearing: [],
    hearingLeft: [],
    hearingRight: [],
    fakeHearing:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2020-03-09T10:54:30.393190',
          year: '2020',
          value: 0
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2021-05-09T10:54:30.393190',
          year: '2021',
          value: -2
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2022-03-09T10:54:30.393190',
          year: '2022',
          value: -3
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-02T10:54:30.393190',
          year: '2023',
          value: -4.5
        }
      ],
    fakeHearingLeft:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-02T10:54:30.393190',
          year: '2020',
          values: [45, 20, 10, 40, 30, 15, 15, 20],
          frequencies: [250, 500, 1000, 2000, 3000, 4000, 6000, 8000]
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-02T10:54:30.393190',
          year: '2021',
          values: [30, 10, 5, 35, 25, 15, 10, 10],
          frequencies: [250, 500, 1000, 2000, 3000, 4000, 5000, 6000]
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-02T10:54:30.393190',
          year: '2022',
          values: [20, 5, 5, 20, 25, 10, 2, 3],
          frequencies: [250, 500, 1000, 2000, 3000, 4000, 5000, 6000]
        }
      ],
    fakeHearingRight:
    [
      {
        rmId: 123,
        workerId: 456,
        date: '2023-05-02T10:54:30.393190',
        year: '2021',
        values: [30, 25, 30, 20, 22, 24, 26, 25],
        frequencies: [250, 500, 1000, 2000, 3000, 4000, 5000, 6000]
      },
      {
        rmId: 123,
        workerId: 456,
        date: '2023-05-02T10:54:30.393190',
        year: '2022',
        values: [15, 23, 23, 18, 17, 15, 15, 20],
        frequencies: [250, 500, 1000, 2000, 3000, 4000, 5000, 6000]
      }
    ],
    hearingLeftOutStandingData: 5,
    hearingRightOutStandingData: 4,
    // hearing: [],
    // hearingLeft: [],
    // hearingRight: [],
    isLoading: false
  },
  mutations: {
    loadDataHearing (state, payload) {
      // console.log('loadData', payload)
      state.hearing = payload
    },
    loadDataHearingLeft (state, payload) {
      // console.log('loadData', payload)
      state.hearingLeft = payload
    },
    loadDataHearingRight (state, payload) {
      // console.log('loadData', payload)
      state.hearingRight = payload
    },
    resetState (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    lastDateHearing (state) { // fecha del último dato con valor
      const itemsWithValue = state.hearing.filter(el => el.value != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    lastDateHearingLeft (state) { // fecha del último dato con valor
      const itemsWithValue = state.hearingLeft.filter(el => el.values != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    lastDateHearingRight (state) { // fecha del último dato con valor
      const itemsWithValue = state.hearingRight.filter((el) => el.values != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    outstandingDataHearing (state) { // valor del último dato recogido
      // console.log('ENTRA AQUI LINE 154 HEARING STORE')
      const itemsWithValue = state.hearing.filter(el => el.value !== null && el !== undefined && !isNaN(el.value))
      // console.log('ESTO ES EL VALUEEE', itemsWithValue)
      const valuesArray = itemsWithValue.map(function (el) {
        return el.value
      })
      return valuesArray.pop()
    },
    outstandingDataHearingLeft (state) { // TO DO !!
      // const itemsWithValue = state.hearing.filter(el => el.value != null)
      // const valuesArray = itemsWithValue.map(function (el) {
      //   return el.value
      // })
      // return valuesArray.pop()
      if (state.hearingLeft.length !== 0) {
        const result = []
        state.hearingLeft.forEach(element => {
          const itemsWithValue = element.values.filter(el => el !== null && el !== undefined)
          // console.log('itemsWithValue', itemsWithValue)
          const valuesArray = itemsWithValue.map(function (el) {
            return el
          })
          if (valuesArray.length !== 0) result.push(valuesArray.pop())
        })
        // console.log(result)
        if (result.length !== 0) {
          return result.pop()
        } else {
          return null
        }
      } else {
        return null
      }
    },
    outstandingDataHearingRight (state) { // TO DO !!
      if (state.hearingRight.length !== 0) {
        const result = []
        state.hearingRight.forEach(element => {
          const itemsWithValue = element.values.filter(el => el !== null && el !== undefined)
          // console.log('itemsWithValue', itemsWithValue)
          const valuesArray = itemsWithValue.map(function (el) {
            return el
          })
          if (valuesArray.length !== 0) result.push(valuesArray.pop())
        })
        // console.log(result)
        if (result.length !== 0) {
          return result.pop()
        } else {
          return null
        }
      } else {
        return null
      }
    },
    filteredDataHearing (state) { // solo datos
      const objectValues = state.hearing.map(function (el) {
        return -el.value
      })
      // console.log('filteredDatahearing', objectValues)
      return objectValues
    },
    filteredDataHearingLeft (state) { // solo datos
      // console.log('filteredDatahearing', objectValues)
      return state.hearingLeft.filter(x => x.values.length !== 0)
        .map(function (el) {
          return el.values
        }).reverse()
    },
    datesHearingLeft (state) {
      return state.hearingLeft.filter(x => x.values.length !== 0)
        .map(function (el) {
          if (el.values.length !== 0) return el.year
        }).reverse()
    },
    filteredDataHearingRight (state) { // solo datos
      return state.hearingRight.filter(x => x.values.length !== 0)
        .map(function (el) {
          return el.values
        }).reverse()
    },
    datesHearingRight (state) {
      return state.hearingRight.filter(x => x.values.length !== 0)
        .map(function (el) {
          return el.year
        }).reverse()
    },
    filteredXlabelsHearing (state) { // solo label del eje Y
      const objectKeys = state.hearing.map(function (el) {
        return el.year
      })
      return objectKeys
    },
    filteredXlabelsHearingLeft (state) {
      return state.fakeHearingLeft[0].frequencies
    },
    filteredXlabelsHearingRight (state) {
      return state.fakeHearingLeft[0].frequencies
    }
  },
  actions: {
    fetchHearing ({ commit, state }) {
      if (state.hearing.length === 0) {
        state.isLoading = true
        kpiService.getHearing()
          .then((response) => {
            // console.log('DATA Hearing', response.data)
            const data = []
            response.data.forEach(element => {
              const x = element
              x.value = element.overallLoss
              data.push(x)
            })
            commit('loadDataHearing', data)
            // commit('loadDataHearing', state.fakeHearing) // datos de pruebas
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            // console.log('finally 7')
            state.isLoading = false
          })
      }
    },
    fetchHearingLeft ({ commit, state }) {
      if (state.hearing.length === 0) {
        state.isLoading = true
        kpiService.getHearingLeft()
          .then((response) => {
            // console.log('DATA Hearing', response.data)
            // commit('loadDataHearing', response.data)
            const data = []

            response.data.forEach(element => {
              const x = {
                rrmId: element.rmId,
                workerId: element.workerId,
                date: element.date,
                year: element.year,
                values: [],
                frequencies: [250, 500, 1000, 2000, 3000, 4000, 5000, 6000]
              }
              if (element.aud250 !== undefined && element.aud250 !== null) {
                x.values.push(element.aud250)
              }
              if (element.aud500 !== undefined && element.aud500 !== null) {
                x.values.push(element.aud500)
              }
              if (element.aud1000 !== undefined && element.aud1000 !== null) {
                x.values.push(element.aud1000)
              }
              if (element.aud2000 !== undefined && element.aud2000 !== null) {
                x.values.push(element.aud2000)
              }
              if (element.aud3000 !== undefined && element.aud3000 !== null) {
                x.values.push(element.aud3000)
              }
              if (element.aud4000 !== undefined && element.aud4000 !== null) {
                x.values.push(element.aud4000)
              }
              if (element.aud6000 !== undefined && element.aud6000 !== null) {
                x.values.push(element.aud6000)
              }
              if (element.aud8000 !== undefined && element.aud8000 !== null) {
                x.values.push(element.aud8000)
              }
              data.push(x)
            })
            commit('loadDataHearingLeft', data)
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            // console.log('finally 7')
            state.isLoading = false
          })
      }
    },
    fetchHearingRight ({ commit, state }) {
      if (state.hearing.length === 0) {
        state.isLoading = true
        kpiService.getHearingRight()
          .then((response) => {
            // console.log('DATA Hearing', response.data)
            // commit('loadDataHearing', response.data)
            const data = []

            response.data.forEach(element => {
              const x = {
                rrmId: element.rmId,
                workerId: element.workerId,
                date: element.date,
                year: element.year,
                values: [],
                frequencies: [250, 500, 1000, 2000, 3000, 4000, 5000, 6000]
              }
              if (element.aud250 !== undefined && element.aud250 !== null) {
                x.values.push(element.aud250)
              }
              if (element.aud500 !== undefined && element.aud500 !== null) {
                x.values.push(element.aud500)
              }
              if (element.aud1000 !== undefined && element.aud1000 !== null) {
                x.values.push(element.aud1000)
              }
              if (element.aud2000 !== undefined && element.aud2000 !== null) {
                x.values.push(element.aud2000)
              }
              if (element.aud3000 !== undefined && element.aud3000 !== null) {
                x.values.push(element.aud3000)
              }
              if (element.aud4000 !== undefined && element.aud4000 !== null) {
                x.values.push(element.aud4000)
              }
              if (element.aud6000 !== undefined && element.aud6000 !== null) {
                x.values.push(element.aud6000)
              }
              if (element.aud8000 !== undefined && element.aud8000 !== null) {
                x.values.push(element.aud8000)
              }
              data.push(x)
            })
            commit('loadDataHearingRight', data)
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            // console.log('finally 7')
            state.isLoading = false
          })
      }
    }
  }
}
