<template>
  <div class="card">
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <UpDocModal :data="docModalData" :docTitle="docTitle" :isLoading="isLoading" @onClose="closeDocModal()" v-if="isDocModalVisible" />
    <Modal :params="shareModalParamsFinal" :isShareLoading="isShareLoading" v-if="isModalVisible" @onSendDocument="sendDocument($event)" @onClose="closeModal()" @onSendFile="sendEmailFile($event)"/>
    <h1 class="subsection__title">{{ $t('menu.healthMonitoring.documentsUpload') }}</h1>
    <p>
        {{ $t('documentsUpload.intro') }}
    </p>
    <!-- TODO: el cierre de este banner se debería de enviar al menos al local host. -->
    <SimpleAdvice :text="$t('documentsUpload.advice')" :iconClass="'icon-secure-area'" v-if="!skeletonIsVisible && showButtonUpload"/>
    <!-- <NoResults :type="CARD_TYPE.OTHER_DOCUMENTATION" v-if="otherDocuments.length === 0 && !skeletonIsVisible" /> -->
    <div style="display: flex;justify-content: center;" v-if="!skeletonIsVisible">
      <Button
        v-if="showButtonUpload"
        :label="$t('documentsUpload.uploadDocument')"
        classes="button button--cta"
        :icon="'icon-add'"
        @click.native="openModal"
      />
      <SimpleAdvice v-else :text="$t('documentsUpload.maxDocuments')" :iconClass="'icon-forbidden'" :bgColor="'yellow'" :showCloseIcon="false"/>
    </div>
    <SkeletonCard v-if="skeletonIsVisible"/>
    <CardList
      v-if="uploadedDocuments.length > 0"
      :data="uploadedDocuments"
      @onClickPreview="openPreview($event)"
      @onClickDownload="openDownload($event)"
      @onClickShareLink="openShareModal($event)"
      @onClickDelete="deleteDocument($event)"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import UpDocModal from '@/components/modals/UpDocModal.vue'
import Toast from '@/components/toasts/Toast.vue'
import SimpleAdvice from '@/components/ui/SimpleAdvice.vue'
import Modal from '@/components/modals/Modal.vue'
import Button from '@/components/ui/Button.vue'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
// import NoResults from '@/components/commons/NoResults.vue'
import CardList from '@/components/commons/CardList.vue'
import uploadDocumentService from '@/services/uploadDocumentService.js'
import * as _files from '@/_helpers/files.helper.js'
export default {
  name: 'DocumentsUpload',
  components: { UpDocModal, CardList, Modal, SkeletonCard, Toast, SimpleAdvice, Button },
  data () {
    return {
      MAX_DOCS: window.UP_DOC_MAX_FILES,
      MAX_BYTES: window.UP_DOC_MAX_BYTES,
      shareModalParamsFinal: null,
      isShareLoading: false,
      isLoading: false,
      uploadedDocuments: [],
      skeletonIsVisible: true,
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE,
      recognitionData: null,
      isDocModalVisible: false,
      isModalVisible: false,
      isToastVisible: false,
      docModalData: null,
      docTitle: null,
      shareModalParams: {
        type: 3,
        text: null
      },
      shareModalParamsEmail: {
        type: 6,
        title: this.$i18n.t('global.sendByEmail'),
        isLoading: false
      },
      toastParams: null
    }
  },
  computed: {
    showButtonUpload () {
      return this.uploadedDocuments.length <= this.MAX_DOCS
      // return this.uploadedDocuments.length < 2
    }
  },
  mounted () {
    this.getUploadedDocuments()
  },
  methods: {
    openShareModal: function (item) {
      // console.log('event', item)
      this.shareModalParamsEmail.documentId = item.id
      this.shareModalParamsFinal = this.shareModalParamsEmail
      this.isModalVisible = true
    },
    openModal: function (file) {
      this.shareModalParams.file = file
      this.shareModalParams.title = this.$i18n.t('documentsUpload.uploadDocument')
      this.shareModalParamsFinal = this.shareModalParams
      this.isModalVisible = true
    },
    closeModal: function () {
      this.isModalVisible = false
    },
    openPreview: function (item) {
      this.docModalData = null
      this.docTitle = null
      if (item.fileExtension !== 'pdf') return
      this.isDocModalVisible = true
      this.isLoading = true
      uploadDocumentService.getUploadedDocument(item.id)
        .then((response) => {
          this.docModalData = response.data.file
          this.docTitle = response.data.fileName.split('.').slice(0, -1).join('.')
        })
        .catch(() => {
          this.isLoading = true
          this.isDocModalVisible = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    closeDocModal: function () {
      this.isDocModalVisible = false
    },
    sendDocument: function (item) {
      // console.log(item)
      const formData = {}
      if (item.aliasName) formData.userFileName = item.aliasName
      formData.fileType = item.docType.name
      formData.fileName = item.fileName
      formData.file = item.file
      formData.description = item.comment
      formData.fileExtension = item.fileExtension
      // console.log(formData, 'formData')
      uploadDocumentService.uploadDocument(formData)
        .then((response) => {
          // console.log(response)
        })
        .catch(() => {
        })
        .finally(() => {
          this.isToastVisible = true
          this.toastParams = {
            text: this.$t('documentsUpload.uploadDocumentFinished'),
            type: 'success',
            time: 5000
          }
          setTimeout(() => {
            this.isToastVisible = false
          }, 7000)
          this.isModalVisible = false
          this.getUploadedDocuments()
        })
    },
    deleteDocument: function (item) {
      const x = this.uploadedDocuments.indexOf(item)
      // console.log(x)
      // console.log(item)
      if (x > -1) {
        this.uploadedDocuments[x].isDeleteLoading = true
        // console.log(this.uploadedDocuments[x])
      }
      uploadDocumentService.deleteDocument(item.id)
        .then((response) => {
          // console.log(response)
          // _files.downloadFile(response.data.file, response.data.fileName)
        })
        .catch(() => {
        })
        .finally(() => {
          this.uploadedDocuments[x].isDeleteLoading = false
          this.getUploadedDocuments()
        })
    },
    getUploadedDocuments: function () {
      this.uploadedDocuments = []
      this.skeletonIsVisible = true
      uploadDocumentService.getUploadedDocuments()
        .then((response) => {
          // console.log(response)
          response.data.forEach(element => {
            element.type = 11
            element.isLoading = false
            element.isDeleteLoading = false
          })
          this.uploadedDocuments = response.data
        })
        .catch(() => {
        })
        .finally(() => {
          this.skeletonIsVisible = false
        })
    },
    openDownload: function (item) {
      const x = this.uploadedDocuments.indexOf(item)
      // console.log(x)
      // console.log(item)
      if (x > -1) {
        this.uploadedDocuments[x].isLoading = true
        // console.log(this.uploadedDocuments[x])
      }
      uploadDocumentService.getUploadedDocument(item.id)
        .then((response) => {
          // console.log(response)
          _files.downloadFile(response.data.file, response.data.fileName)
        })
        .catch(() => {
        })
        .finally(() => {
          this.uploadedDocuments[x].isLoading = false
        })
    },
    sendEmailFile: function (data) {
      this.isShareLoading = true
      uploadDocumentService.shareUploadedDocument(data.documentId, data.fileName, data.toEmail)
        .then((response) => {
          // console.log(response)
        })
        .catch(() => {
        })
        .finally(() => {
          this.isToastVisible = true
          this.toastParams = {
            text: this.$t('documentsUpload.uploadDocumentFinished'),
            type: 'success',
            time: 5000
          }
          this.isShareLoading = false
          setTimeout(() => {
            this.isToastVisible = false
          }, 7000)
          this.isModalVisible = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/scss/settings/variables';

.my-digital-health {
  overflow: hidden;
  &__body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 13px;
    padding-left: 1px;
    padding-right: 1px;
    & h2 {
      flex-basis: 100%;
      margin-top: 15px;
      margin-bottom: 0;
      font-size: $font-size-xl;
    }
  }
}

</style>
