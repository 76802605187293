import Vue from 'vue'
import Vuex from 'vuex'
import anotherStore from '@/store/anotherStore'
import menuStore from '@/store/menuStore'
import userStore from '@/store/userStore'
import dataStore from '@/store/dataStore'
import termsStore from '@/store/termsStore'
import kpisWeightStore from '@/store/kpisWeightStore'
import kpisImcStore from '@/store/kpisImcStore'
import kpisVisionStore from '@/store/kpisVisionStore'
import kpisHearingStore from '@/store/kpisHearingStore'
import kpisSpirometryStore from '@/store/kpisSpirometryStore'
import kpisUrineGlucoseStore from '@/store/kpisUrineGlucoseStore'
import kpisBloodPressStore from '@/store/kpisBloodPressStore'
import kpisCholesterolTotalStore from '@/store/kpisCholesterolTotalStore'
import kpisUricAcidStore from '@/store/kpisUricAcidStore'
import kpiConfigStore from '@/store/kpiConfigStore'
import kpisHematiesStore from '@/store/kpisHematiesStore'
import kpisHematocritoStore from '@/store/kpisHematocritoStore'
import kpisHemoglobinStore from '@/store/kpisHemoglobinStore'
import kpisGgtStore from '@/store/kpisGgtStore'
import kpisGotStore from '@/store/kpisGotStore'
import kpisGptStore from '@/store/kpisGptStore'
import kpisHdlStore from '@/store/kpisHdlStore'
import kpisLdlStore from '@/store/kpisLdlStore'
import kpisTriglyceridesStore from '@/store/kpisTriglyceridesStore'
import kpisPsaStore from '@/store/kpisPsaStore'
import kpisGlucoseStore from '@/store/kpisGlucoseStore'
import i18n from '@/i18n.js'
Vue.use(Vuex)

const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'), {
  moment
})

export default new Vuex.Store({
  state: {
    flag: false,
    path: '',
    resources: [],
    locale: i18n.locale,
    languages: [
      {
        id: 1,
        name: i18n.t('global.spanish'),
        code: 'es',
        order: 1,
        active: true,
        activeInExtranet: true,
        activeInIexpedient: true
      },
      {
        id: 2,
        name: i18n.t('global.catalan'),
        code: 'ca',
        order: 2,
        active: true,
        activeInExtranet: true,
        activeInIexpedient: true
      },
      {
        id: 3,
        name: i18n.t('global.basque'),
        code: 'eu',
        order: 3,
        active: false,
        activeInExtranet: false,
        activeInIexpedient: false
      },
      {
        id: 4,
        name: i18n.t('global.english'),
        code: 'en',
        order: 4,
        active: true,
        activeInExtranet: false,
        activeInIexpedient: false
      }
    ],
    currentUser: JSON.parse(sessionStorage.getItem('currentUser')),
    breadcrumbLinks: [],
    legalContent: {},
    isToastVisible: false,
    isFAQSModalVisible: false,
    token: sessionStorage.getItem('token'),
    currentDevice: null,
    isTPSModalVisible: false
  },
  mutations: {
    setPath (state, path) {
      state.path = path
    },
    setCurrentDevice (state, device) {
      state.currentDevice = device
    },
    setFlag (state, flag) {
      state.flag = flag
    },
    setResources (state, resources) {
      state.resources = resources
    },
    setLanguages (state, languages) {
      state.languages = languages
    },
    setCurrentUser (state, user) {
      state.currentUser = user
      sessionStorage.setItem('currentUser', JSON.stringify(user))
    },
    setBreadcrumbLinks (state, breadcrumbLinks) {
      state.breadcrumbLinks = breadcrumbLinks
    },
    setToken (state, token) {
      state.token = token
      sessionStorage.setItem('token', token)
    },
    setLegalContent (state, legalContent) {
      switch (legalContent) {
        case 'terms':
          state.legalContent = {
            title: i18n.t('register.serviceTerms'),
            text: i18n.t('register.serviceTermsText')
          }
          break
        case 'privacyPolicy':
          state.legalContent = {
            title: i18n.t('register.privacyPolicy'),
            text: i18n.t('register.privacyPolicyText')
          }
          break
        case 'processingOrderContract':
          state.legalContent = {
            title: i18n.t('register.processingOrderContract'),
            text: i18n.t('register.processingOrderContractText')
          }
          break
        default:
          break
      }
    },
    setToastVisible (state, visibility) {
      state.isToastVisible = visibility
    },
    setFAQSModalVisible (state, visibility) {
      state.isFAQSModalVisible = visibility
    },
    setLocale (state, locale) {
      state.locale = locale
    },
    setTPSModalVisible (state, visibility) {
      state.isTPSModalVisible = visibility
    }
  },
  getters: {
    getPath (state) {
      return state.path
    },
    getCurrentDevice (state) {
      return state.currentDevice
    },
    getFlag (state) {
      return state.flag
    },
    getResources (state) {
      return state.resources
    },
    getLanguages (state) {
      return state.languages
    },
    getCurrentUser (state) {
      return state.currentUser
    },
    getBreadcrumbLinks (state) {
      return state.breadcrumbLinks
    },
    getLegalContent (state) {
      return state.legalContent
    },
    getToken (state) {
      return state.token
    },
    getIsToastVisible (state) {
      return state.isToastVisible
    },
    getIsFAQSModalVisible (state) {
      return state.isFAQSModalVisible
    },
    getLocale (state) {
      return state.locale
    },
    getIsTPSModalVisible (state) {
      return state.isTPSModalVisible
    }
  },
  actions: {
    updateFlag ({ commit }) {
      commit('setFlag', true)
    },
    showToast ({ commit }) {
      commit('setToastVisible', true)
    },
    resetAllState: function () {
      this._modules.root.forEachChild((childModule) => {
        if (typeof childModule._rawModule.mutations.resetState === 'function') {
          // console.log(childModule)
          childModule.context.commit('resetState')
        }
      })
    }
  },
  modules: {
    modal: anotherStore, menuStore, userStore, dataStore, termsStore, kpisWeightStore, kpisImcStore, kpisVisionStore, kpisSpirometryStore, kpisUrineGlucoseStore, kpisBloodPressStore, kpisCholesterolTotalStore, kpisUricAcidStore, kpiConfigStore, kpisHematiesStore, kpisHematocritoStore, kpisHemoglobinStore, kpisGgtStore, kpisGotStore, kpisGptStore, kpisHdlStore, kpisLdlStore, kpisTriglyceridesStore, kpisHearingStore, kpisPsaStore, kpisGlucoseStore
  }
})
