export function downloadPDF (data, fileName) {
  const byteString = atob(data)
  // console.log(byteString)
  // separate out the mime component
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  // create a view into the buffer
  const ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // console.log(data)
  const FileSaver = require('file-saver')
  const blob = new Blob([ab])
  FileSaver.saveAs(blob, fileName + '.pdf')
}

export function downloadFile (data, fileNameExtension) {
  const byteString = atob(data)
  // console.log(byteString)
  // separate out the mime component
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  // create a view into the buffer
  const ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // console.log(data)
  const FileSaver = require('file-saver')
  const blob = new Blob([ab])
  FileSaver.saveAs(blob, fileNameExtension)
}
