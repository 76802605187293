import moment from 'moment'
import kpiService from '@/services/kpiService.js'

function initialState () {
  return {
    vision: [],
    isLoading: false
  }
}

export default {
  state: {
    visionFake:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2020-03-09T10:54:30.393190',
          year: '2020',
          value: 9.54
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2021-05-09T10:54:30.393190',
          year: '2021',
          value: 9.75
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2022-03-09T10:54:30.393190',
          year: '2022',
          value: 8.45
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-02T10:54:30.393190',
          year: '2023',
          value: 6.89
        }
      ],
    visionLeftFake:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2020-03-09T10:54:30.393190',
          year: '2020',
          value: 8.54
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2021-05-09T10:54:30.393190',
          year: '2021',
          value: 7.75
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2022-03-09T10:54:30.393190',
          year: '2022',
          value: 7.89
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-06-03T10:54:30.393190',
          year: '2023',
          value: 6.25
        }
      ],
    visionRightFake:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2020-03-09T10:54:30.393190',
          year: '2020',
          value: 9.54
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2021-05-09T10:54:30.393190',
          year: '2021',
          value: 8.75
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2022-03-09T10:54:30.393190',
          year: '2022',
          value: 8.89
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-07-04T10:54:30.393190',
          year: '2023',
          value: 7.25
        }
      ],
    vision: [],
    visionLeft: [],
    visionRight: [],
    isLoading: false
  },
  mutations: {
    loadDataVision (state, payload) {
      // console.log('loadData', payload)
      state.vision = payload
    },
    loadDataVisionLeft (state, payload) {
      // console.log('loadData', payload)
      state.visionLeft = payload
    },
    loadDataVisionRight (state, payload) {
      // console.log('loadData', payload)
      state.visionRight = payload
    },
    resetState (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    lastDateVision (state) { // fecha del último dato con valor
      // return moment().format('LL')
      if (state.vision.length !== 0) {
        let finDate = null
        state.vision.forEach(element => {
          const itemsWithValue = element.dates.filter(el => el != null)
          // console.log('itemsWithValue', itemsWithValue)
          const valuesArray = itemsWithValue.map(function (el) {
            return el
          })
          if (finDate === null) {
            finDate = valuesArray.pop()
          } else if (Date(moment(finDate).format('LL')) < new Date(moment(valuesArray.pop()).format('LL'))) {
            finDate = valuesArray.pop()
          }
        })
        return moment(finDate).format('LL')
      } else {
        return null
      }
    },
    lastDateVisionLeft (state) { // fecha del último dato con valor
      if (state.visionLeft.length !== 0) {
        let finDate = null
        state.visionLeft.forEach(element => {
          const itemsWithValue = element.dates.filter(el => el != null)
          // console.log('itemsWithValue', itemsWithValue)
          const valuesArray = itemsWithValue.map(function (el) {
            return el
          })
          if (finDate === null) {
            finDate = valuesArray.pop()
          } else if (Date(moment(finDate).format('LL')) < new Date(moment(valuesArray.pop()).format('LL'))) {
            finDate = valuesArray.pop()
          }
        })
        return moment(finDate).format('LL')
      } else {
        return null
      }
    },
    lastDateVisionRight (state) { // fecha del último dato con valor
      if (state.visionRight.length !== 0) {
        let finDate = null
        state.visionRight.forEach(element => {
          const itemsWithValue = element.dates.filter(el => el != null)
          // console.log('itemsWithValue', itemsWithValue)
          const valuesArray = itemsWithValue.map(function (el) {
            return el
          })
          if (finDate === null) {
            finDate = valuesArray.pop()
          } else if (Date(moment(finDate).format('LL')) < new Date(moment(valuesArray.pop()).format('LL'))) {
            finDate = valuesArray.pop()
          }
        })
        return moment(finDate).format('LL')
      } else {
        return null
      }
    },
    outstandingDataVision (state) { // valor del último dato recogido
      if (state.vision.length !== 0) {
        const result = []
        state.visionLeft.forEach(element => {
          const itemsWithValue = element.values.filter(el => el !== null && el !== undefined && el !== 0)
          // console.log('itemsWithValue', itemsWithValue)
          const valuesArray = itemsWithValue.map(function (el) {
            return el
          })
          result.push(valuesArray.pop())
        })
        if (result.length !== 0) {
          return result[0]
        } else {
          return null
        }
      } else {
        return null
      }
    },
    outstandingDataVisionLeft (state) { // valor del último dato recogido
      if (state.visionLeft.length !== 0) {
        const result = []
        state.visionLeft.forEach(element => {
          const itemsWithValue = element.values.filter(el => el !== null && el !== undefined && el !== 0)
          // console.log('itemsWithValue', itemsWithValue)
          const valuesArray = itemsWithValue.map(function (el) {
            return el
          })
          result.push(valuesArray.pop())
        })
        if (result.length !== 0) {
          return result[0]
        } else {
          return null
        }
      } else {
        return null
      }
    },
    outstandingDataVisionRight (state) { // valor del último dato recogido
      if (state.visionRight.length !== 0) {
        const result = []
        state.visionRight.forEach(element => {
          const itemsWithValue = element.values.filter(el => el !== null && el !== undefined && el !== 0)
          // console.log('itemsWithValue', itemsWithValue)
          const valuesArray = itemsWithValue.map(function (el) {
            return el
          })
          result.push(valuesArray.pop())
        })
        if (result.length !== 0) {
          return result[0]
        } else {
          return null
        }
      } else {
        return null
      }
    },
    filteredDataVision (state) { // solo datos
      const objectValues = state.vision.map(function (el) {
        return el.values
      })
      // console.log('filteredDatavision', objectValues)
      return objectValues
    },
    filteredDataVisionLeft (state) { // solo datos
      const objectValues = state.visionLeft.map(function (el) {
        return el.values
      })
      // console.log('filteredDatavisionLeft', objectValues)
      return objectValues
    },
    filteredDataVisionRight (state) { // solo datos
      const objectValues = state.visionRight.map(function (el) {
        return el.values
      })
      // console.log('filteredDatavision', objectValues)
      return objectValues
    },
    filteredXlabelsVision (state) { // solo label del eje Y
      const objectKeys = state.vision.map(function (el) {
        return el.years
      })
      return objectKeys[0]
    },
    filteredXlabelsVisionLeft (state) { // solo label del eje Y
      const objectKeys = state.visionLeft.map(function (el) {
        return el.years
      })
      return objectKeys[0]
    },
    filteredXlabelsVisionRight (state) { // solo label del eje Y
      const objectKeys = state.visionRight.map(function (el) {
        return el.years
      })
      return objectKeys[0]
    },
    datesVision (state) {
      const arr = []
      if (state.vision !== 0) {
        state.vision.forEach(element => {
          arr.push(element.type)
        })
      }
      return arr
    },
    datesVisionLeft (state) {
      const arr = []
      if (state.visionLeft !== 0) {
        state.visionLeft.forEach(element => {
          arr.push(element.type)
        })
      }
      return arr
    },
    datesVisionRight (state) {
      const arr = []
      if (state.visionRight !== 0) {
        state.visionRight.forEach(element => {
          arr.push(element.type)
        })
      }
      return arr
    }
  },
  actions: {
    fetchVision ({ commit, state }) { // (commit, state, kpi)
      // console.log('FETCH DESDE STORE Vision')
      if (state.vision.length === 0) {
        state.isLoading = true
        kpiService.getVision()
          .then((response) => {
            // console.log('DATA Vision', response.data)
            // commit('loadDataVision', response.data)
            // console.log('VISION RESPONSE', response.data)

            const lc = []
            if (response.data.leftClose !== undefined) {
              const x = {
                values: [],
                years: [],
                dates: []
              }
              response.data.leftClose.forEach(element => {
                x.values.push(element.value)
                x.years.push(element.year)
                x.dates.push(element.date)
              })
              x.type = 'leftClose'
              lc.push(x)
            }
            if (response.data.leftFar !== undefined) {
              const x = {
                values: [],
                years: [],
                dates: []
              }
              response.data.leftFar.forEach(element => {
                x.values.push(element.value)
                x.years.push(element.year)
                x.dates.push(element.date)
              })
              x.type = 'leftFar'
              lc.push(x)
            }
            // console.log(lc)
            commit('loadDataVisionLeft', lc)

            const rc = []
            if (response.data.rightClose !== undefined) {
              const x = {
                values: [],
                years: [],
                dates: []
              }
              response.data.rightClose.forEach(element => {
                x.values.push(element.value)
                x.years.push(element.year)
                x.dates.push(element.date)
              })
              x.type = 'rightClose'
              rc.push(x)
            }
            if (response.data.rightFar !== undefined) {
              const x = {
                values: [],
                years: [],
                dates: []
              }
              response.data.rightFar.forEach(element => {
                x.values.push(element.value)
                x.years.push(element.year)
                x.dates.push(element.date)
              })
              x.type = 'rightFar'
              rc.push(x)
            }
            // console.log(rc)
            commit('loadDataVisionRight', rc)

            const bc = []
            if (response.data.visionBinocularClose !== undefined) {
              const x = {
                values: [],
                years: [],
                dates: []
              }
              response.data.visionBinocularClose.forEach(element => {
                x.values.push(element.value)
                x.years.push(element.year)
                x.dates.push(element.date)
              })
              x.type = 'visionBinocularClose'
              bc.push(x)
            }
            if (response.data.visionBinocularFar !== undefined) {
              const x = {
                values: [],
                years: [],
                dates: []
              }
              response.data.visionBinocularFar.forEach(element => {
                x.values.push(element.value)
                x.years.push(element.year)
                x.dates.push(element.date)
              })
              x.type = 'visionBinocularFar'
              bc.push(x)
            }
            // console.log(bc)
            commit('loadDataVision', bc)
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            // console.log('finally 7')
            state.isLoading = false
          })
      }
      // commit('loadDataVision', state.visionFake) // datos de pruebas
      // commit('loadDataVisionLeft', state.visionLeftFake) // datos de pruebas
      // commit('loadDataVisionRight', state.visionRightFake) // datos de pruebas
    }
  }
}
