<template>
    <div class="doc-modal__backdrop">
      <div class="doc-modal">
        <div class="doc-modal__header">
            <div class="doc-modal__header__title-container">
                <span class="doc-modal__header__icon icon icon-back" @click="closeModal()"></span>
                <span class="doc-modal__header__title" v-if="title">{{title}}</span>
            </div>
            <button class="button button--small button--positive">
                <span class="button__icon icon icon-download"></span>
                <span class="button__label">{{ $t('global.download') }}</span>
            </button>
        </div>
        <div class="doc-modal__body" :class="isLoading ? 'loader-container' : ''">
          <span v-if="isLoading" class="icon loader-sppinner icon-is-spinning"/>
          <pdfvuer
            v-for="i in numPages"
            :key="i"
            :src="documentData"
            :page="i"
          ></pdfvuer>
        </div>
      </div>
    </div>
</template>
<script>
// import pdf from 'vue-pdf'
import pdfvuer from 'pdfvuer'
import documentService from '@/services/documentService.js'
export default {
  components: {
    pdfvuer
  },
  name: 'DocModal',
  props: ['data'],
  data () {
    return {
      isLoading: false,
      numPages: undefined,
      documentData: null,
      title: null
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('onClose', false)
    },
    initial () {
      const blob = new Blob([this.documentData])
      const objectUrl = URL.createObjectURL(blob)
      this.pdfsrc = objectUrl
      pdfvuer.createLoadingTask(this.documentData).then(p => {
        this.numPages = p.numPages
      })
    },
    getDocument (item) {
      if (this.documentData) {
        return
      }
      this.isLoading = true
      documentService.getDocument(item.uuid, item.documentType).then((response) => {
        // console.log()
        this.title = response.data.nombreFichero
        this.documentData = 'data:application/pdf;base64,' + response.data.fichero
        // console.log()
        this.initial()
      })
        .catch(() => {
          // console.log('hay un error')
          this.$emit('onClose', true)
        })
        .finally(() => {
          // console.log()
          this.isLoading = false
        })
    }
  },
  mounted () {
    this.getDocument(this.data)
  }
}
</script>

<style scoped>

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-sppinner {
  border: 2px solid rgb(187 189 188);
  border-left-color: transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

</style>
